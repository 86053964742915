import React, { Component } from 'react'
import axios from 'axios'
import { uniqBy } from 'lodash'
import Spinner from '../Spinner/Spinner'
import Error from '../Error/Error'
import { PrimaryButton } from '../Button/Button'

import {
  Container,
  List,
  ListItem,
  Name,
  SubHeader,
  Refresh
} from './styled'

class Summary extends Component {
  state = {
    items: [],
    loading: true,
    error: false
  }

  componentDidMount () {
    this.getResponses()
  }

  getResponses = async () => {
    this.setState({
      loading: true
    })
    try {
      const responses = await axios.get(`${process.env.REACT_APP_READ}responses`)
      const invites = await axios.get(`${process.env.REACT_APP_READ}data`)
      const convertedInvites = invites.data.data.map(item => ({
        fullname: `${item.firstname} ${item.lastname}`,
        alias: item.alias,
        response: 'noreply'
      }))
      const allItems = [
        ...convertedInvites,
        ...responses.data.data
      ]
      const transformed = uniqBy(allItems.reverse(), 'alias')
      this.setState({
        items: transformed,
        loading: false,
        error: false
      })
    } catch (e) {
      console.error(e)
      this.setState({
        loading: false,
        error: true
      })
    }
  }

  makeres

  renderList = (type) => {
    const { items } = this.state
    const filtered = items.filter(item => item.response === type)
    if (type === 'yes' && filtered.length === 0) {
      return <ListItem>Никто не придет :(</ListItem>
    }
    if (type === 'no' && filtered.length === 0) {
      return <ListItem>Ура, никто не отказался!</ListItem>
    }
    if (type === 'noreply' && filtered.length === 0) {
      return <ListItem>Все уже ответили!</ListItem>
    }
    return filtered.map(item => (
      <ListItem key={item.alias}>
        <Name>{item.fullname}</Name>
      </ListItem>
    ))
  }

  render () {
    const { loading, error } = this.state

    if (loading) {
      return <Spinner loading={loading} />
    }

    if (error) {
      return <Error />
    }

    return (
      <Container>
        <SubHeader>Придут:</SubHeader>
        <List>
          {this.renderList('yes')}
        </List>
        <SubHeader>Не придут:</SubHeader>
        <List>
          {this.renderList('no')}
        </List>
        <SubHeader>Не ответили:</SubHeader>
        <List>
          {this.renderList('noreply')}
        </List>
        <Refresh>
          <PrimaryButton onClick={this.getResponses}>Обновить</PrimaryButton>
        </Refresh>
      </Container>
    )
  }
}

export default Summary
