import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'

const StyledNotFound = styled.div`
  background-color: ${props => props.theme.pink};
  color: #fff;
  height: 300px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const NotFound = () => (
  <StyledNotFound>
    <Icon icon='meh-blank' size='3x' style={{ marginBottom: '20px' }} />
    Тут ничего нет. И не будет.
  </StyledNotFound>
)

export default NotFound
