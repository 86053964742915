import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'

const StyledError = styled.div`
  background-color: ${props => props.theme.pink};
  color: #fff;
  height: 300px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const Error = () => (
  <StyledError>
    <Icon icon='sad-cry' size='3x' style={{ marginBottom: '20px' }} />
    Не удалось получить данные, попробуйте позже.
  </StyledError>
)

export default Error
