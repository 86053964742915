import React from 'react'
import PropTypes from 'prop-types'
import { BeatLoader } from 'react-spinners'
import {
  Container
} from './styled'

const Spinner = ({ loading }) => {
  return (
    <Container>
      <BeatLoader
        color='#fff'
        loading={loading}
      />
    </Container>
  )
}

Spinner.propTypes = {
  loading: PropTypes.bool
}

export default Spinner
