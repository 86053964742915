import React, { Component } from 'react'
import axios from 'axios'
import copyTextToClipboard from './utils'
import Spinner from '../Spinner/Spinner'
import Error from '../Error/Error'
import { PrimaryButton } from '../Button/Button'

import {
  Container,
  List,
  ListItem,
  Refresh,
  Hint
} from './styled'

class Links extends Component {
  state = {
    items: [],
    loading: true,
    error: false
  }

  componentDidMount () {
    this.getLinks()
  }

  getLinks = async () => {
    this.setState({
      loading: true
    })
    try {
      const invites = await axios.get(`${process.env.REACT_APP_READ}data`)
      const convertedInvites = invites.data.data.map(item => ({
        fullname: `${item.firstname} ${item.lastname}`,
        alias: item.alias
      }))
      this.setState({
        items: convertedInvites,
        loading: false,
        error: false
      })
    } catch (e) {
      console.error(e)
      this.setState({
        loading: false,
        error: true
      })
    }
  }

  copyLink = (alias) => () => {
    const link = `${window.location.origin}/#/invite/${alias}`
    copyTextToClipboard(link)
  }

  renderList = (type) => {
    const { items } = this.state
    return items.map(item => (
      <ListItem key={item.alias} onClick={this.copyLink(item.alias)}>
        {item.fullname}
      </ListItem>
    ))
  }

  render () {
    const { loading, error } = this.state

    if (loading) {
      return <Spinner loading={loading} />
    }

    if (error) {
      return <Error />
    }

    return (
      <Container>
        <Hint>Нажмите на имя чтобы скопировать ссылку на приглашение.</Hint>
        <List>
          {this.renderList()}
        </List>
        <Refresh>
          <PrimaryButton onClick={this.getLinks}>Обновить</PrimaryButton>
        </Refresh>
      </Container>
    )
  }
}

export default Links
