import { createGlobalStyle } from 'styled-components'
import ReformaTTF from '../../assets/fonts/reformagroteskdemic-webfont.ttf'
import ReformaWOFF from '../../assets/fonts/reformagroteskdemic-webfont.woff'
import PtTTF from '../../assets/fonts/PTS55F_W.ttf'
import PtWOFF from '../../assets/fonts/PTS55F_W.woff'
import bgDesktop from '../../assets/images/bg-desktop.jpg'
import bgMobile from '../../assets/images/bg-mobile.jpg'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Reforma Grotesk Demi';
    src:
      url(${ReformaTTF}) format('truetype'),
      url(${ReformaWOFF}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PT Sans';
    src:
      url(${PtTTF}) format('truetype'),
      url(${PtWOFF}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  :root {
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }

  html, body {
    font-size: inherit;
  }

  body {
    background-image: url(${bgDesktop});
    background-size: contain;
    background-repeat: repeat;
    background-attachment: fixed;

    @media (max-width: 800px) {
      background-image: url(${bgMobile});
      background-repeat: no-repeat;
      background-attachment: initial;
      background-size: cover;
      background-position: center top;
    }
  }
`

export default GlobalStyle
