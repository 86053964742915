import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import Spinner from '../Spinner/Spinner'
import Error from '../Error/Error'
import Icon from '../Icon/Icon'
import {
  PrimaryButton,
  SecondaryButton
} from '../Button/Button'
import {
  Container,
  Header,
  SubHeader,
  Section,
  Strong,
  Pink,
  Name,
  Photo,
  Lead,
  Place,
  Fun,
  Schedule,
  ScheduleItem,
  Question,
  Buttons,
  Address,
  Map,
  MapLink
} from './styled'
import parking from '../../assets/images/parking.jpg'
import walking from '../../assets/images/walking.jpg'

export default class Invite extends React.Component {
  static propTypes = {
    match: PropTypes.object
  }

  state = {
    alias: '',
    firstname: '',
    fullname: '',
    photo: '',
    loading: true,
    error: false,
    sendingFeedback: false,
    feedbackSent: false
  }

  componentDidMount () {
    this.getInvites()
  }

  getInvites = async () => {
    const { slug } = this.props.match.params
    try {
      const response = await axios.get(`${process.env.REACT_APP_READ}data`)
      const { data } = response.data
      const invite = data.find(item => item.alias === slug)
      const { firstname = '', lastname = '', photo = '', alias = '' } = invite
      this.setState({
        alias,
        photo,
        firstname: firstname,
        fullname: `${firstname} ${lastname}`,
        loading: false,
        error: false
      })
    } catch (e) {
      console.error(e)
      this.setState({
        loading: false,
        error: true
      })
    }
  }

  sendFeedback = feedback => async () => {
    const { alias, fullname } = this.state
    if (alias) {
      this.setState({
        sendingFeedback: true
      })
      try {
        await axios.post(process.env.REACT_APP_WRITE, {
          alias,
          fullname: fullname,
          response: feedback
        })
        this.setState({
          feedbackSent: true,
          sendingFeedback: false
        })
      } catch (e) {
        console.error(e)
        this.setState({
          feedbackSent: false,
          sendingFeedback: false
        })
      }
    }
  }

  render () {
    const { firstname, loading, error, photo, feedbackSent, sendingFeedback } = this.state
    if (loading) {
      return <Spinner loading={loading} />
    }

    if (error) {
      return <Error />
    }

    if (feedbackSent) {
      return <Redirect to='/thanks' />
    }

    return (
      <Container>
        <Pink>
          <Section>
            <Header>
              {photo && <Photo src={photo} />}
              <Name>{firstname}!</Name>
            </Header>
            <Lead>Приглашаем вас на бизнес-завтрак<br />“Медиа и Досуг. Новые возможности”.</Lead>
            <Place>
              <div>
                <Strong>Когда: </Strong>
                <span>13 декабря | 10:00</span>
              </div>
              <div>
                <Strong>Где: </Strong>
                <span>BAMBOO.BAR</span>
              </div>
            </Place>
            <Fun>
              <p>Вы отлично проведете декабрьское утро :)</p>
              <p>Бокал легкого шампанского создаст настроение, полезная информация зарядит позитивом, а вкусный завтрак придаст сил.</p>
            </Fun>
            <SubHeader>Тайминг</SubHeader>
            <Schedule>
              <ScheduleItem>10:00-11:00 Сбор гостей.</ScheduleItem>
              <ScheduleItem>11.00-11.30 Деловая часть.</ScheduleItem>
              <ScheduleItem>11.30-13:00 Свободное общение.</ScheduleItem>
            </Schedule>
            <Question>Вы придете на завтрак?</Question>
            <Buttons>
              {
                sendingFeedback
                  ? <Icon icon='spinner-third' spin />
                  : <React.Fragment>
                    <PrimaryButton onClick={this.sendFeedback('yes')}>Я пойду!</PrimaryButton>
                    <SecondaryButton onClick={this.sendFeedback('no')}>Нет</SecondaryButton>
                  </React.Fragment>
              }
            </Buttons>
          </Section>
        </Pink>
        <Section>
          <SubHeader>Как проехать</SubHeader>
          <Address>
            <p>Москва-Сити, Пресненская набережная, дом 8, стр. 1</p>
            <p>Башня Город-Столиц, 1 этаж</p>
          </Address>
          <SubHeader>
            <span>Как пройти от метро </span>
            (
              <MapLink target="_blank" rel="noopener noreferrer" href='https://yandex.ru/maps/213/moscow/?from=api-maps&l=map&ll=37.539307%2C55.748082&mode=routes&origin=jsapi_2_1_71&rtext=55.749025%2C37.539904~55.747139%2C37.539730&rtt=mt&um=constructor%3A42053666afbe1deb8da719007cc87399d10421e3b47896f3ca02ae3530a10c09&z=17'>Яндекс карты</MapLink>
            )
          </SubHeader>
          <Map src={walking} />
          <SubHeader>
            Где припарковаться
            (
              <MapLink target="_blank" rel="noopener noreferrer" href='https://yandex.ru/maps/213/moscow/?from=api-maps&l=map%2Ccarparks&ll=37.540090%2C55.747990&mode=usermaps&origin=jsapi_2_1_71&um=constructor%3A42053666afbe1deb8da719007cc87399d10421e3b47896f3ca02ae3530a10c09&z=16'>Яндекс карты</MapLink>
            )
          </SubHeader>
          <Map src={parking} />
        </Section>
      </Container>
    )
  }
}
