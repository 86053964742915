import styled from 'styled-components'
import { PrimaryButton } from '../Button/Button'

export const Container = styled.div`
  background-color: ${props => props.theme.pink};
  color: #fff;
  padding: 40px 100px;

  @media (max-width: 800px) {
    padding: 20px;
  }
`

export const List = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ListItem = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Refresh = styled.div`
  text-align: center;
`

export const Hint = styled.div`
  padding: 20px;
  border: 2px solid #fff;
  text-align: center;
  margin-bottom: 20px;
`
