import React from 'react'
import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import 'normalize-css/normalize.css'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import App from './components/App/App'

library.add(fal, fab)

ReactDOM.render(<App />, document.getElementById('root'))
