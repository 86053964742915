import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import { Container } from './styled'
import { HashRouter, Switch, Route } from 'react-router-dom'
import theme from '../../theme'
import GlobalStyle from '../GlobalStyle/GlobalStyle'
import Page from '../Page/Page'
import Invite from '../Invite/Invite'
import NotFound from '../NotFound/NotFound'
import Thanks from '../Thanks/Thanks'
import Links from '../Links/Links'
import Summary from '../Summary/Summary'
import Header from '../Header/Header'

class App extends Component {
  render () {
    return (
      <HashRouter>
        <ThemeProvider theme={theme}>
          <Container>
            <Page>
              <Header />
              <Switch>
                <Route exact path='/invite/:slug' component={Invite} />
                <Route exact path='/thanks' component={Thanks} />
                <Route exact path='/links' component={Links} />
                <Route exact path='/summary' component={Summary} />
                <Route exact path='/thanks' component={Thanks} />
                <Route component={NotFound} />
              </Switch>
            </Page>
            <GlobalStyle />
          </Container>
        </ThemeProvider>
      </HashRouter>
    )
  }
}

export default App
