import styled from 'styled-components'

const Page = styled.div`
  margin: 0 auto;
  /* background-color: #fff; */
  max-width: 800px;
  min-height: calc(100vh - 80px);
`

export default Page
