import styled from 'styled-components'

const Button = styled.button`
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 30px;
  width: 100px;
  line-height: 20px;
  margin-right: 20px;
  font-size: 20px;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const PrimaryButton = styled(Button)`
  background-color: #fff;
  color: #000;
  border: 2px solid #fff;

  &:hover {
    background-color: ${props => props.theme.blue};
    border-color: ${props => props.theme.blue};
  }
`

export const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;

  &:hover {
    color: ${props => props.theme.pink};
    background-color: #fff;
  }
`
