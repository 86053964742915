import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${props => props.theme.pink};
  color: #fff;
  padding: 40px 100px;

  @media (max-width: 800px) {
    padding: 20px;
  }
`

export const List = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Name = styled.div`
  flex-grow: 1;
`

export const SubHeader = styled.h2`
  margin: 0 0 5px;
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
`

export const Refresh = styled.div`
  text-align: center;
`
