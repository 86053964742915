import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/images/logo.svg'

const StyledHeader = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fff;

  @media (max-width: 800px) {
    background-color: transparent;
  }
`

const TagLine = styled.div`
  font-size: 12px;
  margin-bottom: 6px;
`

const Header = () => {
  return (
    <StyledHeader>
      <TagLine>Лучший сайт о событиях в Москве</TagLine>
      <img src={logo} alt='Логотип Ваш Досуг' />
    </StyledHeader>
  )
}

export default Header
