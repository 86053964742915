import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const SubHeader = styled.h2`
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin: 0 0 5px;
  font-size: 20px;
`

export const Section = styled.div`
  padding: 40px 100px;

  @media (max-width: 800px) {
    padding: 20px;
  }
`

export const Strong = styled.span`
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
`

export const Pink = styled.div`
  background-color: ${props => props.theme.pink};
  color: #fff;
`

export const Name = styled.span`
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
`

export const Photo = styled.div`
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.src});
  width: 60px;
  height: 60px;
  background-color: #c0c0c0;
  margin-right: 20px;
`

export const Lead = styled.div`
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 30px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`

export const Place = styled.div`
  margin-bottom: 20px;
`

export const Fun = styled.div`
  margin-bottom: 20px;

  p {
    margin: 0;
  }
`

export const Schedule = styled.div`
  margin-bottom: 20px;
`

export const ScheduleItem = styled.div`

`

export const Question = styled.div`
  text-align: center;
  font-family: 'Reforma Grotesk Demi', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 20px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export const Address = styled.div`
  margin-bottom: 20px;
  p {
    margin: 0;
  }
`

export const Map = styled.img`
  max-width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const MapLink = styled.a`
  color: ${props => props.theme.pink}
`
